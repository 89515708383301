/*
    Popup an alternative to Dialog
*/

frappe.provide('frappe.ui');

window.cur_popup = null;

frappe.ui.open_popups = [];

frappe.ui.PopUp = class PopUp {
	constructor(opts) {
		this.display = false;
		this.is_dialog = true;

		$.extend(this, { animate: true, size: null }, opts);
		this.make();
	}

	make() {
		this.$wrapper = this.get_popup_wrapper();

		this.wrapper = this.$wrapper.get(0);

		this.popup_body = this.$wrapper.find(".popup-body");
		this.$body = this.popup_body
		this.body = this.$body.get(0);
		this.$header = this.$wrapper.find(".popup-header");
        this.$title = this.$wrapper.find(".popup-title");
		this.$subtitle = this.$wrapper.find(".popup-subtitle");

		this.footer = this.$wrapper.find(".popup-footer");
		this.buttons = this.footer.find('.buttons');
		this.$right_col_form = $(".step:visible");
		if(!this.only_initiate){
			this.$right_col_form.hide();
		}

        this.make_head();

		this.set_indicator();

		// make fields (if any)
		this.set_popup_message();

		// show footer
		this.action = this.action || { primary: { }, secondary: { } };
		
		this.set_primary_action(this.primary_action_label, this.primary_action);
		if(!this.static){
			this.set_secondary_action(this.secondary_action_label, this.secondary_action);
			this.set_close_action();
		}else{
			this.$wrapper.find(".btn-close").addClass("d-none");
		}

	}

	set_popup_message(fields=[]){
		if(!fields.length){
			fields = this.fields;
		}
		if(fields && fields.length){
			$.each(fields, (idx, fieldobj)=>{
				$(`<div class="row mb-3">
					<div class="col-2 field-description d-flex justify-content-end align-items-center pr-0">
						${__(fieldobj.label)}
					</div>
					<div class="col-10">
						<div class="control-input-field input-group input-group-lg input-group-lg-control border border-dark control-input-field-wrapper justify-content-between w-100"
							data-fieldname="year_field">
							<input type="text" data-fieldname="${fieldobj.label}" readonly
								autocomplete="off" placeholder="" value="${fieldobj.value}"
								class="js-text-form form-control border-0 bold"
								maxlength="140" name="yearField">
							<div class="control-input-value"></div>
						</div>
					</div>
				</div>`).appendTo(this.$body);
			});
		}else if(this.message){
			$(`<p>${this.message}</p>`).appendTo(this.$body);
		}
	}

	init_field(df){
		const field = frappe.ui.form.make_control({
			df: df,
			doctype: this.doctype,
			parent: this.body,
			render_input: true,
		});
	}

	set_title(text) {
		this.$title.text(text);
	}

	set_subtitle(text){
		this.$subtitle.removeClass("d-none").text(text);
	}

	clear() {
		super.clear();
	}

	set_primary_action(label, click) {
		
		this.has_primary_action = true;
		if(!label){
			label = __("Submit");
		}
		if(this.hide_primary_action){
			return;
		}
		if(!this.$primary_btn_wrapper){
			this.$primary_btn_wrapper = $(`<div class="js-form-field text-center position-relative col-12 mt-3 mt-sm-0 pt-5">
				<input type="button" name="next" class="btn btn-warning btn-xl px-0 text-center btn-submit" value="${label}"/>
			</div>`).appendTo(this.popup_body);
		}

		this.get_primary_btn().unbind("click");
		this.get_primary_btn()
			.removeClass("d-none")
			.val(label)
			.click(()=> {
				this.primary_action_fulfilled = true;
				click && click.apply(this, [this]);
			});
	}

	set_secondary_action(label, click) {
		this.has_secondary_action = true;
		if(bond_assets.device.is_mobile_device()){
			label = "Back";
		}else if(!label){
			label = __("Close");
		}

		this.$close_btn = $(`<button class="btn btn-xl d-flex align-items-center mx-auto btn-secondary-action text-center">
			<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14"
				height="10" viewBox="0 0 14 10">
				<g>
					<g>
						<g>
							<path fill="#161617" stroke="#161617"
								stroke-linecap="round" stroke-linejoin="round"
								stroke-miterlimit="20" stroke-width="1.42"
								d="M1.298 4.793h10.663"/>
						</g>
						<g>
							<path fill="#161617" stroke="#161617"
								stroke-linecap="round" stroke-linejoin="round"
								stroke-miterlimit="20" stroke-width="1.42"
								d="M8.788 1.334v0l3.396 3.395v0L8.788 8.125v0"/>
						</g>
					</g>
				</g>
			</svg>${label}
		</button>`).appendTo(this.$wrapper.find(".popup-back"));

		if(bond_assets.device.is_mobile_device()){
			this.$close_btn.addClass("justify-content-center");
		}
		this.get_secondary_action()
			.removeClass("d-none")
			.click(()=> {
				this.display = false;

				if(frappe.ui.open_dialogs[frappe.ui.open_dialogs.length-1]===this) {
					frappe.ui.open_dialogs.pop();
					if(frappe.ui.open_dialogs.length) {
						window.cur_dialog = frappe.ui.open_dialogs[frappe.ui.open_dialogs.length-1];
					} else {
						window.cur_dialog = null;
					}
				}
				if(click){
					click && click.apply(this, [this]);
				}else{
					this.hide();
				}
			});	
	}

	set_close_action(){
		this.get_close_btn().on("click", ()=>{
			this.hide(true);
		});
	}

	disable_primary_action() {
		this.get_primary_btn().addClass('disabled');
	}
	enable_primary_action() {
		this.get_primary_btn().removeClass('disabled');
	}
	get_primary_btn() {
		return this.$wrapper.find(".popup-body .btn-submit");
	}
	get_secondary_action(){
		return this.$wrapper.find(".popup-back .btn-secondary-action")
	}
	get_close_btn() {
		return this.$wrapper.find(".popup-close .btn-close");
	}
	no_cancel() {
		this.get_close_btn().toggle(false);
	}
	cancel() {
		this.get_close_btn().trigger("click");
	}
	make_head() {
		this.set_title(this.title);
	}
	set_indicator() {
		if (this.indicator) {
			this.$header.find('.indicator').removeClass().addClass('indicator ' + this.indicator);
		}
	}
	show() {
		// show it
		this.$wrapper.removeClass("d-none");
		// clear any message
		this.primary_action_fulfilled = false;
		this.is_visible = true;
		this.display = true;
		if(this !== window.cur_popup){
			window.cur_popup = this;
			frappe.ui.open_popups.push(this);
		}
		this.on_page_show && this.on_page_show();
		return this;
	}
	hide(from_hide=false) {
		this.$wrapper.addClass("d-none");
		this.is_visible = false;
		this.display = false;
		if(frappe.ui.open_popups[frappe.ui.open_popups.length-1]===this) {
			frappe.ui.open_popups.pop();
			if(frappe.ui.open_popups.length) {
				window.cur_popup = frappe.ui.open_popups[frappe.ui.open_popups.length-1];
			} else {
				window.cur_popup = null;
			}
		}
		this.$right_col_form.show();
		if(from_hide){
			this.onhide && this.onhide();
			this.on_hide && this.on_hide();
		}
		// remove the element
		if(!this.only_initiate){
			this.$wrapper.remove();
		}

	}

    get_popup_wrapper(){
        return $(`<div class="popup position-fixed d-none">
            <div class="popup-inner-wrap mx-auto w-75">
                <div class="popup-header">
                    <div class="popup-title text-center"></div>
					<div class="popup-subtitle d-none"></div>
                </div>
                <div class="fields-wrap popup-body mx-sm-auto mt-4 mt-sm-5 pt-3 pt-sm-1"></div>
				<div class="text-center mt-3 mt-sm-4 popup-footer">
					<div class="popup-back text-center mt-3 mt-sm-4"></div>
				</div>
            </div>
            <div class="popup-close position-absolute d-sm-flex">
                <button class="btn btn-close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11"
                        viewBox="0 0 10 11">
                        <g>
                            <g>
                                <g>
                                    <path fill="none" stroke="#161617" stroke-linecap="round"
                                        stroke-miterlimit="20" stroke-width="1.5"
                                        d="M1 1.143v0l6.657 7.196v0L9.193 10v0"/>
                                </g>
                                <g transform="rotate(91 5.095 5.57)">
                                    <path fill="none" stroke="#161617" stroke-linecap="round"
                                        stroke-miterlimit="20" stroke-width="1.5"
                                        d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>`).appendTo("main");
    }
};


frappe.confirm_popup = function(message, ifyes, ifno, title, yes_btn_label, no_btn_label, args={}) {
	let fields = [];
	if($.isArray(message)){
		fields = message;
		message = "";
	}
	args = $.extend(args, {
		title: title || __("Confirm"),
		message: message,
		fields: fields,
		primary_action_label:yes_btn_label || __("Yes"),
		primary_action: ()=> {
			if(ifyes) ifyes();
			d.hide();
		},
		secondary_action_label: no_btn_label || __("No"),
		secondary_action: ()=> {
			if(ifno){
				ifno();
			}
		},
		onhide: ()=>{
			if(ifno) {
				ifno();
			}
		}
	});
	var d = new frappe.ui.PopUp(args);

	d.show();

	// flag, used to bind "okay" on enter
	d.confirm_dialog = true;

	// no if closed without primary action
	return d;
}

frappe.popup_print = function(message, title, primary_action_label, primary_action, static=false){
	if($.isPlainObject(message)){
		title = message.title;
		primary_action_label = message.primary_action_label;
		primary_action = message.primary_action;
		static = message.static;
		message = message.message;
	}
	let p = new frappe.ui.PopUp({
		title: title || __("Message"),
		message: null,
		primary_action_label: primary_action_label || __("Close"),
		primary_action: ()=>{
			if(primary_action){
				primary_action();
			}else{
				p.hide();
			}
		},
		static: static,
	});

	p.$wrapper.find(".popup-footer").addClass("d-none");
	p.$title.html(`<div class="popup-title text-center">${message}</div>`);

	p.show();
	
	return p;
}
